import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useStateContext } from '../context/ContextProvider'
import { getUser } from '../utils/utils'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const GetValue = () => {
    const [isLoginEnded, setIsLoginEnded] = useState(false)
    const [getValue, setGetValue] = useState("")
    const [desiredVals, setDesiredVals] = useState([])
    const [listenerStarted, setListenerStarted] = useState(false)
    const { socketDB, messages, sleep, deviceID,findMyID  } = useStateContext()
    const navigate = useNavigate();
    useEffect(() => {
        const user = getUser()
        if (user != null) {
            socketDB.emit("db", { process: "login", data: user }, async (result) => {
                if (!result) {
                    navigate("/")
                } else {
                    setIsLoginEnded(true)
                }
            })
        } else {
            navigate("/")
            setIsLoginEnded(true)
        }
    }, [])

    const setData = async (table, id, column, data) => {
        if (table === "messages") {
            let myIndex =findMyID(messages,id)
            if (Object.values(messages[myIndex].message[0])[0]) {
                toast.error(t("unfinished"))
            }
            else {
                await socketDB.emit("db", { process: "upgrade", table: table, id: id, column: column, data: data })
            }

        } else {
            await socketDB.emit("db", { process: "upgrade", table: table, id: id, column: column, data: data })
        }

    }

    const valueListener = async () => {
        if (!listenerStarted) {
            setListenerStarted(true)
            while (true) {
                await socketDB.emit("db", { process: "getByID", table: "ibold", id: deviceID }, async (result) => {
                    setDesiredVals(result.getvalue)
                })
                await sleep(1000)
            }
        }
    }
    const handleGetValueChange = (e) => {
        setGetValue(e.target.value)
    }
    // useEffect(() => {
    //     console.log(messages)
    // }, [messages])
    // useEffect(() => {
    //     console.log(desiredVals)
    // }, [desiredVals])

    const handleGetValueSubmit = async (e) => {
        e.preventDefault()
        if (getValue !== "" && getValue.startsWith("0x") && getValue.split("0x")[1].length <= 4 && getValue.split("0x")[1].length > 0) {
            let doesHave = false
            for (let i = 0; i < desiredVals.length; i++) {
                const item = Object.keys(desiredVals[i])[0];
                if (item == getValue) {
                    doesHave = true
                    break;
                }
            }
            if (!doesHave) {
                setData("ibold", deviceID, "getvalue", [...desiredVals, { [getValue]: -1 }])
                setDesiredVals([...desiredVals, { [getValue]: -1 }])
                setData("messages", deviceID, "message", [{ "getvalue": true }])
                toast.success(t("success"))
                valueListener()
                setGetValue("")
            } else {
                toast.error(t("requestedBefore"))
                setGetValue("")
            }
        } else if (getValue === "") {
            toast.error(t("theValueCodeCantBeEmpty"))
        } else if (!getValue.startsWith("0x")) {
            toast.error(t("valueStartWith"))
            //1000 42768
        } else if (getValue.split("0x")[1].length > 4) {
            toast.error(t("valueLongerThan"))
        } else if (getValue.split("0x")[1].length === 0) {
            toast.error(t("zeroChar"))
        }
        else {
            toast.error(t("theValueCodeCantBeEmpty"))
        }
    }

    const handleDelete = (e) => {
        window.location.reload()
        // e.preventDefault()
        // setDesiredVals([])
        // toast.success("Başarıyla Silindi")
    }
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Toaster />
            {isLoginEnded ? (<> <form _ngcontent-ng-c334237783="" noValidate="" ng-reflect-form="[object Object]"
                className="ng-untouched ng-pristine ng-invalid">
                <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around">
                    <div _ngcontent-ng-c334237783="" className="col"></div>
                </div>
                <hr _ngcontent-ng-c334237783="" />
                <div _ngcontent-ng-c334237783="" className="text-center mb-2 mt-3"><label _ngcontent-ng-c334237783=""
                    className="boldcenter" style={{ fontSize: "large" }}> {t("getvalue").toLocaleUpperCase()}</label></div>
                <div _ngcontent-ng-c334237783="" className="row mt-3">
                    <div _ngcontent-ng-c334237783="" className="col-md-2"></div>
                    <div _ngcontent-ng-c334237783="" className="col-md-4"><label _ngcontent-ng-c334237783=""
                        htmlFor="tourdist" className="boldcenter" style={{ color: "#f1d00a" }}> {t("valueCode")}: </label><input
                            _ngcontent-ng-c334237783="" type="text" value={getValue} onChange={(e) => handleGetValueChange(e)}
                            className="form-control ng-untouched ng-pristine ng-invalid" placeholder={t("valueCode")} /></div>
                    <div _ngcontent-ng-c334237783="" className="col-md-4 text-center"><button
                        _ngcontent-ng-c334237783="" id="gecikme" className="btn m-1 mt-3 text-center"
                        style={{ backgroundColor: "green" }} disabled="" onClick={(e) => handleGetValueSubmit(e)}><span _ngcontent-ng-c334237783="" className='text-white'> {t("get")}</span></button>
                        <button
                            _ngcontent-ng-c334237783="" id="gecikme" className="btn m-1 w-fit mt-3 text-center"
                            style={{ backgroundColor: "red" }} disabled="" onClick={(e) => handleDelete(e)}><span _ngcontent-ng-c334237783="" className='text-white'> {t("delete")}</span></button>
                    </div>
                    <hr _ngcontent-ng-c334237783="" className="text-muted mt-3 mb-3" />
                </div>
            </form>
                {desiredVals.length > 0 && (<div className="card mt-3 mb-3">
                    <div className="card-body">
                        <div className="flex flex-col items-center justify-center w-full text-center gap-4">
                            {desiredVals.map((item, index) => (<div key={index}>
                                <div style={{ color: "#03506f" }}>
                                    <h1 ><span > {Object.keys(item)[0]} </span></h1>
                                    <h2 > {Object.values(item)[0]} </h2>
                                </div>
                                {desiredVals.length > 1 && (<div className='h-[1px] bg-gray-700 w-full' />)}
                            </div>))}

                        </div>
                    </div>
                </div>
                )}</>) : (<>
                </>)}

        </div>
    )
}

export default GetValue