import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Home from '../pages/Home';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SettingsIcon from '@mui/icons-material/Settings';
import GamesIcon from '@mui/icons-material/Games';
import Timings from '../pages/Timings';
import Settings from '../pages/Settings';
import itechIcon from '../assets/itechLogo.png'
import { useNavigate } from 'react-router-dom';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { getUser } from '../utils/utils';
import { useStateContext } from '../context/ContextProvider';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function useWindowSize() {
    const [size, setSize] = React.useState([0, 0]);
    React.useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function MyDrawer({ children }) {

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [width, height] = useWindowSize();
    const [hoverItem, setHoverItem] = React.useState("")
    const [isLoggedIn, setIsLoggedIn] = React.useState(false)
    const { socketDB ,deviceID}=useStateContext()
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        const user = getUser()
        if (user != null) {
            socketDB.emit("db", { process: "login", data: user }, async (result) => {
                if (!result) {
                    navigate("/")
                } else {
                    setIsLoggedIn(true)
                }
            })
        } else {
            navigate("/")
            setIsLoggedIn(true)
        }
    }, [])

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleLogout = () => {
        localStorage.removeItem("user")
        sessionStorage.removeItem("user")
        navigate("/")
    }
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ backgroundColor: '#5396da' }}>
                <Toolbar sx={{ display: 'flex', alignItems: "center", }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),


                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div id="logo-big" >
                        <img src={itechIcon} alt="" width={150} />
                    </div>
                    {(width > 530) && (<Typography fontSize={35} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100svw", paddingRight: "150px" }} variant="h6" noWrap component="div">
                        I-BOLD
                    </Typography>)}
                    {isLoggedIn && <p onClick={handleLogout} className='text-center text-nowrap text-red-600 border-2 bg-white border-red-600  font-semibold rounded-xl p-2 mt-3 cursor-pointer select-none' style={{ borderColor: "red" }}>{t("logout")}</p>}
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{ backgroundColor: '#5396da' }}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List >
                    {['Home', 'Manuel', 'Timings', 'Settings', 'Get Value'].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: window.location.pathname == "/" + text.split(" ").join("").toLowerCase() ? "#5396da" : "",
                                    color: window.location.pathname == "/" + text.split(" ").join("").toLowerCase() ? "white" : "gray",
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: '#d3d3d3',
                                    },
                                }}
                                onMouseOver={() => setHoverItem("/" + text.split(" ").join("").toLowerCase())}
                                onMouseLeave={() => setHoverItem("")}
                                onClick={() => {
                                    navigate("/" + text.split(" ").join("").toLowerCase())
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',

                                    }}
                                >
                                    {text === "Home" && <HomeIcon sx={{
                                        color: `${window.location.pathname == "/" + text.split(" ").join("").toLowerCase() || hoverItem == "/" + text.split(" ").join("").toLowerCase() ? "white" : "gray"}`,
                                    }} />}
                                    {text === "Manuel" && <GamesIcon sx={{
                                        color: `${window.location.pathname == "/" + text.split(" ").join("").toLowerCase() || hoverItem == "/" + text.split(" ").join("").toLowerCase() ? "white" : "gray"}`,
                                    }} />}
                                    {text === "Timings" && <AccessTimeFilledIcon sx={{
                                        color: `${window.location.pathname == "/" + text.split(" ").join("").toLowerCase() || hoverItem == "/" + text.split(" ").join("").toLowerCase() ? "white" : "gray"}`,
                                    }} />}
                                    {text === "Settings" && <SettingsIcon sx={{
                                        color: `${window.location.pathname == "/" + text.split(" ").join("").toLowerCase() || hoverItem == "/" + text.split(" ").join("").toLowerCase() ? "white" : "gray"}`
                                    }} />}
                                    {text === "Get Value" && <MoveDownIcon sx={{
                                        color: `${window.location.pathname == "/" + text.split(" ").join("").toLowerCase() || hoverItem == "/" + text.split(" ").join("").toLowerCase() ? "white" : "gray"}`
                                    }} />}
                                </ListItemIcon>
                                <ListItemText primary={t(text.toLocaleLowerCase().split(" ").join(""))} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                {/* <Divider />
                <List>
                    {['All mail', 'Trash', 'Spam'].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List> */}
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 1, pt: 10 }}>
                {children}
            </Box>
        </Box >
    );
}