
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Timings from "./pages/Timings";
import Settings from "./pages/Settings";
import { Routes, Route } from "react-router-dom"
import MyDrawer from "./components/Drawer";
import Manuel from "./pages/Manuel";
import GetValue from "./pages/GetValue";
import PageNotFound from "./pages/PageNotFound";
import Login from "./pages/Login";

const App = () => {
  return (
    <BrowserRouter >
      <Routes>
        <Route exact path="/" element={<Login/>} />
        <Route exact path="/home" element={<MyDrawer><Home /></MyDrawer>} />
        <Route exact path="/timings" element={<MyDrawer><Timings /></MyDrawer>} />
        <Route exact path="/settings" element={<MyDrawer><Settings /></MyDrawer>} />
        <Route exact path="/manuel" element={<MyDrawer><Manuel /></MyDrawer>} />
        <Route exact path="/getvalue" element={<MyDrawer><GetValue /></MyDrawer>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;