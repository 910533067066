import React, { useEffect, useState } from 'react'
import { useStateContext } from '../context/ContextProvider'
import { useNavigate } from 'react-router-dom';
import { getUser } from '../utils/utils';
import { useTranslation } from 'react-i18next'

const Home = () => {
  const [distance, setDistance] = useState(0);
  const [current, setCurrent] = useState(0)
  const [voltage, setVoltage] = useState(0)
  const [time, setTime] = useState("")
  const [statusCode, setStatusCode] = useState()
  const { socketDB, sleep, messages, deviceID, findMyID } = useStateContext()
  const [isLoginEnded, setIsLoginEnded] = useState(false)
  const navigate = useNavigate();

  const checkStatus = async () => {
    const myIndex = findMyID(messages, deviceID)
    if (myIndex != -1 && !messages[myIndex].status) {
      getDBData()
      await socketDB.emit("db", { process: "upgrade", table: "messages", id: deviceID, column: "status", data: true })
    }
  }
  const { t, i18n } = useTranslation();

  const translate = (code) => {
    const codes = {
      "0": "plcStop",
      "1": "plcLowBattery",
      "2": "emergancyStopPressed",
      "3": "limitSwitchPressed",
      "4": "excessCurrentError",
      "5": "pakoSwitchPressed",
      "6": "excessCurrentError",
      "7": "firstLocation",
      "8": "secondLocation",
      "9": "thirdLocation",
      "10": "fourthLocation",
      "11": "onCharge",
      "12": "aboveMagnetic",
      "13": "sonarError30",
      "14": "sonarError300",
      "15": "returnError",
      "16": "magneticError"
    }
    const have = Object.keys(codes).includes(code.toString())
    if (have) {
      return t(codes[code.toString()])
    } else {
      return t("unknownErrorCode")

    }
  }
  useEffect(() => {
    let interval;

    if (messages != null) {
      if (deviceID != -1) {
        interval = setInterval(() => {
          checkStatus()
        }, 1000);
      }
    }

    return () => clearInterval(interval);
  }, [messages, deviceID])

  useEffect(() => {
    const user = getUser()
    if (user != null) {
      socketDB.emit("db", { process: "login", data: user }, async (result) => {
        if (!result) {
          navigate("/")
        } else {
          setIsLoginEnded(true)
        }
      })
    } else {
      navigate("/")
      setIsLoginEnded(true)
    }
  }, [])

  const getDBData = async () => {
    await socketDB.emit("db", { process: "getByID", table: "ibold", id: deviceID }, async (result) => {
      // const data=strToObj(result.status[0])
      setDistance(result.status[0].mesafeG)
      setCurrent(result.status[0].akimG)
      setVoltage(result.status[0].gerilimG)
      setTime(result.status[0].zamanG)
      setStatusCode(result.status[0].statusCode)
    })
  }

  useEffect(() => {
    if (deviceID != -1) {
      getDBData()
    }
  }, [deviceID])

  const isError = (code) => {
    if (code == 6 || code == 7 || code == 8 || code == 9 || code == 10 || code == 11 || code == 12) {
      return false
    } else {
      return true
    }
  }

  //i18n.changeLanguage("tr-TR");
  const handleChange = (e) => {
    if (e.target.value == "tr") {
      i18n.changeLanguage("tr-TR");
    }
    else {
      i18n.changeLanguage("en-EN");
    }
  }
  return (
    <>{isLoginEnded ? (<>
      <div className="container">
        <div className="row">
          <div className="card text-center">
            <div className="row">
              <div className="col-6 mt-2 ">
                <h1 id="clock"> {time} </h1>
              </div>
              <div className="col-6 d-flex align-items-center text-center"><select
                id="languageform" className="form-control ng-untouched ng-pristine ng-valid"
                ng-reflect-model="tr" onChange={handleChange}>
                <option value="tr" ng-reflect-value="tr">TR</option>
                <option value="en" ng-reflect-value="en">EN</option>
              </select></div>
            </div>
          </div>

          <div className="card mt-3 mb-3">
            <div className="card-body">
              <div className="flex flex-col items-center justify-center w-full text-center gap-4">
                <div style={{ color: "#03506f" }}>
                  <h2 ><span > {t("systemVoltage")} </span></h2>
                  <h1 id="voltage" > {voltage} </h1>
                </div>
                <div className='h-[1px] bg-gray-700 w-full' />
                <div className="row" style={{ color: "#03506f" }}>
                  <h2 style={{ textAlign: "center" }}><span > {t("systemCurrent")}</span></h2>
                  <h1 id="amper" > {current} </h1>
                </div>
                <div className='h-[1px] bg-gray-700 w-full' />
                <div className="row" style={{ color: "#03506f" }}>
                  <h2 style={{ textAlign: "center" }}><span >
                    {t("distance")}</span></h2>
                  <h1 id="distance" > {distance} </h1>
                </div>
              </div>
            </div>
          </div>
          {statusCode != null && statusCode != undefined && (
            <div className={`card ${isError(statusCode) ? "bg-red-500" : "bg-green-500"}`}>
              <div className="card-body "><span >{translate(statusCode)}</span>
              </div>
            </div>)}
          {/* <div  className="card mt-3 mb-3">
                    <div  className="card-body">
                        <ul  ngbnav="" className="nav-tabs nav" role="tablist">
                            <li  ngbnavitem="" ng-reflect-_id="" className="nav-item" role="presentation">
                                <button  type="button" ngbnavlink="" id="ngb-nav-0" role="tab"
                                    aria-controls="ngb-nav-0-panel" aria-selected="true" aria-disabled="false"
                                    className="nav-link active">Timings</button>
                            </li>
                            <li  ngbnavitem="" ng-reflect-_id="" className="nav-item" role="presentation">
                                <button  type="button" ngbnavlink="" id="ngb-nav-1" role="tab"
                                    aria-selected="true" aria-disabled="false" className="nav-link active"
                                    aria-controls="ngb-nav-1-panel">Settings</button>
                            </li>

                            <li  ngbnavitem="" ng-reflect-_id="" className="nav-item" role="presentation">
                                <button  type="button" ngbnavlink="" id="ngb-nav-0" role="tab"
                                    aria-selected="false" aria-disabled="false" className="nav-link">Timings</button>
                            </li>
                            <li  ngbnavitem="" ng-reflect-_id="" className="nav-item" role="presentation">
                                <button  type="button" ngbnavlink="" id="ngb-nav-1" role="tab"
                                    aria-selected="false" aria-disabled="false" className="nav-link">Settings</button>
                            </li>
                        </ul>
                    </div>
                </div> */}
        </div>
      </div>
    </>) : (<></>)}</>

  )
}



export default Home