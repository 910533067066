import CryptoJS from "crypto-js";
import { io } from "socket.io-client";

const URL = "http://94.154.34.33:4010"
const socket = io(URL, { autoConnect: true });

export const getUser = () => {
    const user = localStorage.getItem("user");
    const oneTimeUser = sessionStorage.getItem("user");
    if (user != null || oneTimeUser != null) {
        if (user != null) {
            const encryptedUser = decryptData(user);
            return encryptedUser
        } else {
            if (oneTimeUser != null) {
                const encryptedOneTimeUser = decryptData(oneTimeUser);
                return encryptedOneTimeUser
            }
        }
    } else {
        return null;
    }

}

export const LoggedIn = () => {
    const user = localStorage.getItem("user");
    const oneTimeUser = sessionStorage.getItem("user");
    if (user != null || oneTimeUser != null) {
        try {
            if (user != null) {
                const encryptedUser = decryptData(user);
                return login(encryptedUser)
            } else {
                if (oneTimeUser != null) {
                    const encryptedOneTimeUser = decryptData(oneTimeUser);
                    return login(encryptedOneTimeUser)
                }
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    } else {
        return false;
    }
};

const login = async (data) => {
    await socket.emit("db", { process: "login", data: data }, async (result) => {
        return result
    })
}
export const encryptData = (data) => {
    const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_FIREBASE_SECRET_KEY
    ).toString();
    return encrypted;
};

export const decryptData = (encrypted) => {
    const decrypted = CryptoJS.AES.decrypt(
        encrypted,
        process.env.REACT_APP_FIREBASE_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
};

export const getUserInfo = () => {
    const user = localStorage.getItem("user");
    const oneTimeUser = sessionStorage.getItem("user");
    if (user != null) {
        return decryptData(user);
    }
    if (oneTimeUser != null) {
        return decryptData(oneTimeUser);
    }

}
export const encryptEmail = (email) => {
    email = email.replaceAll(".", "-dot-");
    email = email.replaceAll("#", "-slash-");
    email = email.replaceAll("$", "-dollar-");
    email = email.replaceAll("[", "-openBraket-");
    email = email.replaceAll("]", "-closeBraket-");
    return email;
};

export const decryptEmail = (email) => {
    email = email.replaceAll("-dot-", ".");
    email = email.replaceAll("-slash-", "#");
    email = email.replaceAll("-dollar-", "$");
    email = email.replaceAll("-openBraket-", "[");
    email = email.replaceAll("-closeBraket-", "]");
    return email;
};

export const createRegId = () => {
    const regId = encryptData(Date.now());
    localStorage.setItem("regId", regId);
    return regId;
};

export const compareDateTime = (dateTime1, dateTime2) => {
    // Verilen string formatındaki tarihleri ayrıştırma
    const [firstDate, time1] = dateTime1.split(" ");
    const [day1, month1, year1] = firstDate.split("/");
    const [hours1, minutes1, seconds1] = time1.split(":");

    const [secondDate, time2] = dateTime2.split(" ");
    const [day2, month2, year2] = secondDate.split("/");
    const [hours2, minutes2, seconds2] = time2.split(":");

    // Yıl, Ay, Gün, Saat, Dakika, Saniye cinsinden yeni Date nesneleri oluşturma
    const date1 = new Date(
        `${year1}-${month1}-${day1}T${hours1}:${minutes1}:${seconds1}`
    );

    const date2 = new Date(
        `${year2}-${month2}-${day2}T${hours2}:${minutes2}:${seconds2}`
    );

    // console.log(`${year1}-${month1}-${day1}T${hours1}:${minutes1}:${seconds1}`);
    // console.log(`${year2}-${month2}-${day2}T${hours2}:${minutes2}:${seconds2}`);
    if (date1.getTime() > date2.getTime()) {
        return true; // İlk tarih, ikinci tarihten ileridedir
    } else if (date1.getTime() <= date2.getTime()) {
        return false; // İkinci tarih, ilk tarihten ileride veya aynı zamandadır
    }
};

export function isDateInPast(inputDate) {
    // Split the given date into its components in the "DD/MM/YYYY HH:mm:SS" format
    var dateComponents = inputDate.split(/\/|\s|:/);

    // Create the given date
    var givenDate = new Date(
        parseInt(dateComponents[2]), // Year
        parseInt(dateComponents[1]) - 1, // Month (0-11)
        parseInt(dateComponents[0]), // Day
        parseInt(dateComponents[3]), // Hour
        parseInt(dateComponents[4]), // Minute
        parseInt(dateComponents[5]) // Second
    );

    // Get the current date and time
    var currentDate = new Date();

    // Compare the dates and return the result
    return givenDate < currentDate;
}

export const rtCurrentDate = () => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export const rtCurrentHour = () => {
    const date = new Date();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    return `${hour}:${minute}:${second}`;
}
export const calculateRemainingSeconds = (timeString, dateString) => {

    const dateArray = dateString.split('/');
    const timeArray = timeString.split(':');

    const day = parseInt(dateArray[0]);
    const month = parseInt(dateArray[1]) - 1; // JavaScript'te aylar 0'dan başlar (0-11)
    const year = parseInt(dateArray[2]);

    const hours = parseInt(timeArray[0]);
    const minutes = parseInt(timeArray[1]);
    const seconds = parseInt(timeArray[2]);

    const specifiedDate = new Date(year, month, day, hours, minutes, seconds);

    // Şu anki zamanı al
    const currentTime = new Date();

    // Kalan süreyi hesapla
    const remainingTime = specifiedDate.getTime() - currentTime.getTime();
    const remainingSeconds = Math.floor(remainingTime / 1000); // Milisaniyeleri saniyeye çevir

    return remainingSeconds;
};

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const rtHistoryID = () => {
    //YYYYMMDDHHmmSSss
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    const splitSecond = date.getMilliseconds()
    const ID = `${year}${month}${day}${hour}${minute}${second}${splitSecond}`
    return ID
}

export const sortByDateAndTime = (array) => {
    // Yeni bir dizi oluşturup verileri dönüştürüyoruz
    const sortedData = array.map((item) => {
        const keys = Object.keys(item)
        let day, month, year, hour, minute, second
        if (keys.includes("date")) {
            [day, month, year] = item.date.split("/"); // Tarihi ayırıyoruz
            [hour, minute, second] = item.hour.split(":"); // Saati ayırıyoruz  
        }
        else if (keys.includes("startDate")) {
            [day, month, year] = item.startDate.split("/"); // Tarihi ayırıyoruz
            [hour, minute, second] = item.startTime.split(":"); // Saati ayırıyoruz  
        }

        // Yeni bir tarih nesnesi oluşturuyoruz (Yıl, Ay, Gün, Saat, Dakika, Saniye)
        const dateObj = new Date(year, month - 1, day, hour, minute, second);

        return {
            ...item,
            timestamp: dateObj.getTime(), // Tarih ve saat bilgisini milisaniye cinsine çeviriyoruz
        };
    });

    // Sıralama işlemi
    sortedData.sort((a, b) => b.timestamp - a.timestamp);

    // Timestamp bilgisini çıkarıyoruz, sadece sıralama için eklenmişti
    return sortedData.map(({ timestamp, ...rest }, index) => ({ id: index, ...rest }));
};
