import React, { useEffect, useState } from "react";
import { useStateContext } from "../context/ContextProvider";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getUser } from "../utils/utils";
import { useTranslation } from "react-i18next";

let isSettingGot = false;

const Deneme = () => {
  const [actionClick, setActionClick] = useState("");
  const [manuel, setManuel] = useState(false);
  const { socketDB, messages, sleep, deviceID, findMyID } = useStateContext();
  const [isLoginEnded, setIsLoginEnded] = useState(false);
  const [actionHover, setActionHover] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const user = getUser();
    if (user != null) {
      socketDB.emit("db", { process: "login", data: user }, async (result) => {
        if (!result) {
          navigate("/");
        } else {
          setIsLoginEnded(true);
        }
      });
    } else {
      navigate("/");
      setIsLoginEnded(true);
    }
  }, []);

  const setData = async (table, id, column, data) => {
    if (table === "messages") {
      const myIndex = findMyID(messages, deviceID);
      if (Object.values(messages[myIndex].message[0])[0]) {
        toast.error(t("unfinished"));
      } else {
        await socketDB.emit("db", {
          process: "upgrade",
          table: table,
          id: id,
          column: column,
          data: data,
        });
      }
    } else {
      await socketDB.emit("db", {
        process: "upgrade",
        table: table,
        id: id,
        column: column,
        data: data,
      });
    }
  };

  const getSettings = () => {
    console.log("isSettingGot: " + isSettingGot);
    if (!isSettingGot) {
      isSettingGot = true;
      setData("messages", deviceID, "message", [{ getsettings: true }]);
    }
  };

  useEffect(() => {
    if (messages != null) {
      if (deviceID != -1) {
        getSettings();
        const myIndex = findMyID(messages, deviceID);
        if (
          Object.keys(messages[myIndex].message[0]).includes("getsettings") &&
          !messages[myIndex].message[0].getSettings
        ) {
          getDataManuel("ibold", deviceID);
        }
      }
    }
  }, [messages, deviceID]);

  const getDataManuel = async (table, id) => {
    await socketDB.emit(
      "db",
      { process: "getByID", table: table, id: id },
      async (result) => {
        console.log("manuel: " + result.manuel);
        setManuel(result.manuel);
      }
    );
  };

  const handleManuelChange = () => {
    setManuel((prev) => {
      if (!prev === true) {
        setData("ibold", deviceID, "manuel", true);
        setData("messages", deviceID, "message", [{ manuel: true }]);
      } else if (!prev === false) {
        setData("ibold", deviceID, "manuel", false);
        setData("messages", deviceID, "message", [{ manuel: true }]);
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (manuel) {
      setData("ibold", deviceID, "action", actionClick);
      setData("messages", deviceID, "message", [{ action: true }]);
    } else if (!manuel && actionClick != "stop" && actionClick != "") {
      toast.error(t("manuelIsNotActive"));
    }
  }, [actionClick]);

  // const handleMouseUp = () => {
  //   setActionClick("stop");
  //   // if (actionClick != "stop") {
  //   // }
  // };

  const handleStop = () => {
    setData("ibold", deviceID, "action", "stop");
    setData("messages", deviceID, "message", [{ action: true }]);
  };

  // useEffect(() => {
  //   document.addEventListener("mouseup", handleMouseUp);
  //   return () => {
  //     document.removeEventListener("mouseup", handleMouseUp);
  //   };
  // }, []);

  useEffect(() => {
    return () => {
      isSettingGot = false;
    };
  }, []);

  return (
    <>
      {isLoginEnded ? (
        <>
          <div _ngcontent-ng-c334237783="" className="card mt-3">
            <Toaster />
            <div _ngcontent-ng-c334237783="" className="row mt-3">
              <div _ngcontent-ng-c334237783="" className="col-md-4"></div>
              <div
                _ngcontent-ng-c334237783=""
                className="col-md-4 text-center mb-5"
              >
                <input
                  _ngcontent-ng-c334237783=""
                  type="checkbox"
                  checked={manuel}
                  onChange={handleManuelChange}
                  id="btn-check-outlined"
                  autoComplete="off"
                  className="btn-check bg-blue-500"
                />
                <label
                  _ngcontent-ng-c334237783=""
                  htmlFor="btn-check-outlined"
                  className="btn btn-outline-danger "
                >
                  {t("manuelControl")}
                </label>
                <br _ngcontent-ng-c334237783="" />
              </div>
              <div _ngcontent-ng-c334237783="" className="col-md-4"></div>
            </div>
            <div
              _ngcontent-ng-c334237783=""
              className="row justify-content-around"
            >
              <div
                _ngcontent-ng-c334237783=""
                className={`directional-buttons mt-2 mb-2 `}
              >
                <div></div>
                <button
                  onMouseOver={() => setActionHover("up")}
                  onMouseLeave={() => setActionHover("")}
                  onMouseDown={() => setActionClick("forward")}
                  onMouseUp={() => setActionClick("stop")}
                  className="flex relative items-center  justify-center bg-[#0000004d] rounded-t-lg w-20 h-20 shadow-arrow hover:bg-red-500 "
                >
                  <div
                    className={`w-16 h-16 absolute  rotate-45 -bottom-8  hover:bg-red-500 ${actionHover == "up" ? "bg-red-500" : "bg-[#0000004d]"
                      }`}
                  />
                  <div className={`z-10 absolute mb-3 border-l-[33px] border-l-transparent  border-b-[30px]  border-r-[33px] border-r-transparent ${actionClick == "forward" ? "border-b-blue-700" : "border-b-[#ffffff99]"}`} />
                </button>
                <div />
                <button
                  onMouseOver={() => setActionHover("left")}
                  onMouseLeave={() => setActionHover("")}
                  onMouseDown={() => setActionClick("left")}
                  onMouseUp={() => setActionClick("stop")}
                  className="flex relative items-center hover:bg-red-500 justify-center bg-[#0000004d] rounded-t-lg w-20 h-20 shadow-arrow -rotate-90"
                >
                  <div
                    className={`w-16 h-16 absolute  rotate-45 -bottom-8  hover:bg-red-500 ${actionHover == "left" ? "bg-red-500" : "bg-[#0000004d]"
                      }`}
                  />
                  <div className={`z-10 absolute mb-3 border-l-[33px] border-l-transparent  border-b-[30px]  border-r-[33px] border-r-transparent ${actionClick == "left" ? "border-b-blue-700" : "border-b-[#ffffff99]"}`} />
                </button>
                <div></div>
                <button
                  onMouseOver={() => setActionHover("right")}
                  onMouseLeave={() => setActionHover("")}
                  onMouseDown={() => setActionClick("right")}
                  onMouseUp={() => setActionClick("stop")}
                  className="flex relative items-center hover:bg-red-500 justify-center bg-[#0000004d] rounded-t-lg w-20 h-20 shadow-arrow rotate-90"
                >
                  <div
                    className={`w-16 h-16 absolute  rotate-45 -bottom-8  hover:bg-red-500 ${actionHover == "right" ? "bg-red-500" : "bg-[#0000004d]"
                      }`}
                  />
                  <div className={`z-10 absolute mb-3 border-l-[33px] border-l-transparent  border-b-[30px]  border-r-[33px] border-r-transparent ${actionClick == "right" ? "border-b-blue-700" : "border-b-[#ffffff99]"}`} />
                </button>
                <div></div>
                <button
                  onMouseOver={() => setActionHover("down")}
                  onMouseLeave={() => setActionHover("")}
                  onMouseDown={() => setActionClick("backward")}
                  onMouseUp={() => setActionClick("stop")}
                  className="flex relative items-center  justify-center bg-[#0000004d] hover:bg-red-500 rounded-t-lg w-20 h-20 shadow-arrow -rotate-180"
                >
                  <div
                    className={`w-16 h-16 absolute  rotate-45 -bottom-8  hover:bg-red-500 ${actionHover == "down" ? "bg-red-500" : "bg-[#0000004d]"
                      }`}
                  />
                  <div className={`z-10 absolute mb-3 border-l-[33px] border-l-transparent  border-b-[30px] border-r-[33px] border-r-transparent ${actionClick == "backward" ? "border-b-blue-700" : "border-b-[#ffffff99]"}`} />
                </button>
                <div></div>
                {/* <button
                  _ngcontent-ng-c334237783=""
                  id="btnleft"
                  className={`direction-button  left `}
                  disabled=""
                  onMouseDown={() => setActionClick("left")}
                >
                  <span
                    _ngcontent-ng-c334237783=""
                    className={`visually-hidden `}
                  >
                    left
                  </span>
                </button>
                <button
                  _ngcontent-ng-c334237783=""
                  id="btnright"
                  className={`direction-button  right `}
                  disabled=""
                  onMouseDown={() => setActionClick("right")}
                >
                  <span
                    _ngcontent-ng-c334237783=""
                    className={`visually-hidden `}
                  >
                    right
                  </span>
                </button>
                <button
                  _ngcontent-ng-c334237783=""
                  id="btnup"
                  className={`direction-button  up `}
                  disabled=""
                  onMouseDown={() => setActionClick("forward")}
                >
                  <span
                    _ngcontent-ng-c334237783=""
                    className={`visually-hidden `}
                  >
                    up
                  </span>
                </button>
                <button
                  _ngcontent-ng-c334237783=""
                  id="btndown"
                  className={`direction-button down `}
                  disabled=""
                  onMouseDown={() => setActionClick("backward")}
                >
                  <span
                    _ngcontent-ng-c334237783=""
                    className={`visually-hidden `}
                  >
                    down
                  </span>
                </button> */}
              </div>
              <div _ngcontent-ng-c334237783="" className="directional-buttons">
                <button
                  _ngcontent-ng-c334237783=""
                  id="btnstop"
                  className="direction-button stop "
                  onClick={handleStop}
                >
                  <span
                    _ngcontent-ng-c334237783=""
                    style={{
                      fontSize: "xx-large",
                      fontWeight: "bold",
                      color: "#ececec",
                      textAlign: "center",
                    }}
                  >
                    <span _ngcontent-ng-c334237783=""> {t("stop")} </span>
                  </span>
                  <span
                    _ngcontent-ng-c334237783=""
                    className="visually-hidden "
                  >
                    stop
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Deneme;
