import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useStateContext } from '../context/ContextProvider'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../utils/utils'
import { useTranslation } from 'react-i18next'

let isTimingsGot = false;

const Timings = () => {
    const [timings, setTimings] = useState({
        0: { active: false, mesafe: 60, hour: "00:00" },
        1: { active: false, mesafe: 60, hour: "00:00" },
        2: { active: false, mesafe: 60, hour: "00:00" },
        3: { active: false, mesafe: 60, hour: "00:00" },
        4: { active: false, mesafe: 60, hour: "00:00" },
        5: { active: false, mesafe: 60, hour: "00:00" },
        6: { active: false, mesafe: 60, hour: "00:00" },
        7: { active: false, mesafe: 60, hour: "00:00" },
        8: { active: false, mesafe: 60, hour: "00:00" },
        9: { active: false, mesafe: 60, hour: "00:00" },
    })

    const [saveSettings, setSaveSettings] = useState(false)
    const { socketDB, messages, deviceID, findMyID } = useStateContext()

    const [isLoginEnded, setIsLoginEnded] = useState(false)
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        const user = getUser()
        if (user != null) {
            socketDB.emit("db", { process: "login", data: user }, async (result) => {
                if (!result) {
                    navigate("/")
                } else {
                    setIsLoginEnded(true)
                }
            })
        } else {
            navigate("/")
            setIsLoginEnded(true)
        }
    }, [])

    const getTimings = () => {
        if (!isTimingsGot) {
            isTimingsGot = true
            setData("messages", deviceID, "message", [{ "getalarms": true }])
        }
    }
    useEffect(() => {
        if (messages != null) {
            if (deviceID != -1) {
                getTimings()
                const myIndex = findMyID(messages, deviceID)
                if (Object.keys(messages[myIndex].message[0]).includes("getalarms") && !messages[myIndex].message[0].getalarms) {
                    getData("ibold", deviceID)
                }
            }
        }
    }, [messages, deviceID])

    const getData = async (table, id) => {
        await socketDB.emit("db", { process: "getByID", table: table, id: id }, async (result) => {
            for (let i = 0; i < result.timings[0].length; i++) {
                const item = result.timings[0][i];
                for (let j = 0; j < Object.keys(item).length; j++) {
                    const key = Object.keys(item)[j];
                    const val = Object.values(item)[j]
                    handleTimings(i, key, val)
                }
            }
        })
    }
    // useEffect(() => {
    //     Object.keys(timings).length >= 10 && setSaveSettings(false)
    //     Object.keys(timings).length < 10 && setSaveSettings(true)
    // }, [timings])
    useEffect(() => {
        return () => {
            isTimingsGot = false
        }
    }, [])

    const setData = async (table, id, column, data) => {
        if (table === "messages") {
            const myIndex = findMyID(messages, deviceID)
            if (Object.values(messages[myIndex].message[0])[0]) {
                toast.error(t("unfinished"))
            }
            else {
                await socketDB.emit("db", { process: "upgrade", table: table, id: id, column: column, data: data })
            }
        } else {
            await socketDB.emit("db", { process: "upgrade", table: table, id: id, column: column, data: data })
        }
    }

    const handleTimings = (id, key, value) => {
        const newValue = { ...timings };

        if (!newValue[id]) {
            newValue[id] = {};
        }
        newValue[id][key] = value;

        setTimings(newValue);
        if (Object.keys(newValue).length > 9) {
            let isOpened = true;
            for (let i = 0; i < Object.keys(newValue).length; i++) {
                const element = Object.keys(newValue[Object.keys(newValue)[i]]).length
                if (element < 3) {
                    isOpened = false
                    break
                }
            }
            if (isOpened) {
                setSaveSettings(true)
            }
        }
    };

    const handleSave = (e) => {
        e.preventDefault()
        let timeData = [];
        console.log(timings)
        for (let i = 0; i < Object.keys(timings).length; i++) {
            timeData.push(timings[i])
        }
        setData("ibold", deviceID, "timings", timeData)
        console.log(timeData)
        setData("messages", deviceID, "message", [{ "timings": true }])
        toast.success(t("success"))
        return false
    }

    return (
        <>
            {isLoginEnded ? (<>
                < div className="tab-content" >
                    <Toaster></Toaster>
                    <div className="tab-pane fade show active" >
                        <div className="container">
                            <form onSubmit={handleSave} >
                                <div className="row mt-3">
                                    <div className="col-md-6 mt-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row"><label > ALARM
                                                    1</label>
                                                    <div className="col-md-4 mt-3"><label
                                                    >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                        type="checkbox" checked={timings[0].active} onChange={(e) => handleTimings(0, 'active', e.target.checked)} className="m-2 ng-untouched ng-pristine ng-invalid"
                                                        /></div>
                                                    <div className="col-md-4 mt-1"><label
                                                    >{t("distance")}:</label><input
                                                            type="number" value={timings[0].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(0, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid" ng-reflect-min="60"
                                                            ng-reflect-max="150" placeholder="Distance" /></div>
                                                    <div className="col-md-4 mt-1"><label
                                                    >{t("hour")}:</label><input
                                                            type="time" value={timings[0].hour} onChange={(e) => handleTimings(0, 'hour', e.target.value)}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                        /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row"><label> ALARM
                                                    2</label>
                                                    <div className="col-md-4 mt-3"><label
                                                    >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                        type="checkbox" checked={timings[1].active} onChange={(e) => handleTimings(1, 'active', e.target.checked)} className=" m-2 ng-untouched ng-pristine ng-invalid"
                                                        /></div>
                                                    <div className="col-md-4 mt-1"><label
                                                    >{t("distance")}:</label><input
                                                            type="number" value={timings[1].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(1, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid" ng-reflect-min="60"
                                                            ng-reflect-max="150" placeholder="Distance" /></div>
                                                    <div className="col-md-4 mt-1"><label
                                                    >{t("hour")} :</label><input
                                                            type="time" value={timings[1].hour} onChange={(e) => handleTimings(1, 'hour', e.target.value)}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                        /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row"><label > ALARM
                                                        3</label>
                                                        <div className="col-md-4 mt-3"><label
                                                        >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="checkbox" checked={timings[2].active} onChange={(e) => handleTimings(2, 'active', e.target.checked)}
                                                            className="m-2 ng-untouched ng-pristine ng-invalid" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("distance")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="number" value={timings[2].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(2, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            ng-reflect-min="60" ng-reflect-max="150"
                                                            placeholder="Distance" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("hour")}:</label><input
                                                                type="time" value={timings[2].hour} onChange={(e) => handleTimings(2, 'hour', e.target.value)}
                                                                className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row"><label> ALARM
                                                        4</label>
                                                        <div className="col-md-4 mt-3"><label
                                                        >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="checkbox" checked={timings[3].active} onChange={(e) => handleTimings(3, 'active', e.target.checked)}
                                                            className="m-2 ng-untouched ng-pristine ng-invalid" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("distance")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="number" value={timings[3].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(3, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            ng-reflect-min="60" ng-reflect-max="150"
                                                            placeholder="Distance" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("hour")}:</label><input
                                                                type="time" value={timings[3].hour} onChange={(e) => handleTimings(3, 'hour', e.target.value)}
                                                                className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div _ngcontent-ng-c334237783="">
                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row"><label> ALARM
                                                        5</label>
                                                        <div className="col-md-4 mt-3"><label
                                                        >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="checkbox" checked={timings[4].active} onChange={(e) => handleTimings(4, 'active', e.target.checked)}
                                                            className="m-2 ng-untouched ng-pristine ng-invalid" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("distance")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="number" value={timings[4].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(4, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            ng-reflect-min="60" ng-reflect-max="150"
                                                            placeholder="Distance" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("hour")}:</label><input
                                                                type="time" value={timings[4].hour} onChange={(e) => handleTimings(4, 'hour', e.target.value)}
                                                                className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row"><label> ALARM
                                                        6</label>
                                                        <div className="col-md-4 mt-3"><label
                                                        >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="checkbox" checked={timings[5].active} onChange={(e) => handleTimings(5, 'active', e.target.checked)}
                                                            className="m-2 ng-untouched ng-pristine ng-invalid" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("distance")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="number" value={timings[5].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(5, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            ng-reflect-min="60" ng-reflect-max="150"
                                                            placeholder="Distance" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("hour")}:</label><input
                                                                type="time" value={timings[5].hour} onChange={(e) => handleTimings(5, 'hour', e.target.value)}
                                                                className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            /></div>
                                                    </div>
                                                </div >
                                            </div >
                                        </div>
                                    </div>
                                </div>
                                <div _ngcontent-ng-c334237783="">
                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row"><label> ALARM
                                                        7</label>
                                                        <div className="col-md-4 mt-3"><label
                                                        >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="checkbox" checked={timings[6].active} onChange={(e) => handleTimings(6, 'active', e.target.checked)}
                                                            className="m-2 ng-untouched ng-pristine ng-invalid" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("distance")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="number" value={timings[6].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(6, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            ng-reflect-min="60" ng-reflect-max="150"
                                                            placeholder="Distance" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("hour")}:</label><input
                                                                type="time" value={timings[6].hour} onChange={(e) => handleTimings(6, 'hour', e.target.value)}
                                                                className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row"><label> ALARM
                                                        8</label>
                                                        <div className="col-md-4 mt-3"><label
                                                        >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="checkbox" checked={timings[7].active} onChange={(e) => handleTimings(7, 'active', e.target.checked)}
                                                            className="m-2 ng-untouched ng-pristine ng-invalid" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("distance")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="number" value={timings[7].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(7, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            ng-reflect-min="60" ng-reflect-max="150"
                                                            placeholder="Distance" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("hour")}:</label><input
                                                                type="time" value={timings[7].hour} onChange={(e) => handleTimings(7, 'hour', e.target.value)}
                                                                className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div _ngcontent-ng-c334237783="">
                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row"><label> ALARM
                                                        9</label>
                                                        <div className="col-md-4 mt-3"><label
                                                        >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="checkbox" checked={timings[8].active} onChange={(e) => handleTimings(8, 'active', e.target.checked)}
                                                            className="m-2 ng-untouched ng-pristine ng-invalid" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("distance")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="number" value={timings[8].mesafe} name="" min="60" max="150" step="5" onChange={(e) => handleTimings(8, 'mesafe', parseInt(e.target.value))}
                                                            className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            ng-reflect-min="60" ng-reflect-max="150"
                                                            placeholder="Distance" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("hour")}:</label><input
                                                                type="time" value={timings[8].hour} onChange={(e) => handleTimings(8, 'hour', e.target.value)}
                                                                className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row"><label> ALARM
                                                        10</label>
                                                        <div className="col-md-4 mt-3"><label
                                                        >{t("active")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="checkbox" checked={timings[9].active} onChange={(e) => handleTimings(9, 'active', e.target.checked)}
                                                            className="m-2 ng-untouched ng-pristine ng-invalid" /></div>
                                                        <div className="col-md-4 mt-1"><label
                                                        >{t("distance")}:</label><input _ngcontent-ng-c334237783=""
                                                            type="number" value={timings[9].mesafe} name="" min="60" max="150" onChange={(e) => handleTimings(9, 'mesafe', parseInt(e.target.value))}
                                                            step="5" className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            ng-reflect-min="60" ng-reflect-max="150"
                                                            placeholder="Distance" /></div>
                                                        <div className="col-md-4 mt-1">
                                                            <label>{t("hour")}:</label>
                                                            <input
                                                                type="time" value={timings[9].hour} onChange={(e) => handleTimings(9, 'hour', e.target.value)}
                                                                className="form-control text-center ng-untouched ng-pristine ng-invalid"
                                                            /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col text-center">
                                        <button className="btn btn-primary m-4" type='submit' disabled={!saveSettings}
                                        > {t("saveSettings")}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </>) : (<></>)}
        </>
    )
}

export default Timings