import * as yup from "yup";

export const loginSchema = yup.object({
    deviceID: yup
        .string()
        .required("Cihaz ID'sini boş bırakamazsınız!"),
    password: yup
        .string()
        .required("Şifreyi boş bırakamazsınız!")
        .min(8, "En az 8 karakter giriniz!"),
});