import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useStateContext } from '../context/ContextProvider'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../utils/utils'
import { useTranslation } from 'react-i18next'

let isSettingGot = false;

const Settings = () => {
    const [spiral, setSpiral] = useState(false)
    const [distance, setDistance] = useState(0)
    const [refHiz, setRefHiz] = useState(0)
    const [motionControl, setMotionControl] = useState({
        rampaStart: 0,
        delay: 0,
        rampaTime: 0
    })
    const [currentControl, setCurrentControl] = useState({
        first: 0,
        second: 0
    })
    const [limitSwitch, setLimitSwitch] = useState(false)

    const { socketDB, messages, deviceID, findMyID } = useStateContext()
    const [isLoginEnded, setIsLoginEnded] = useState(false)
    const { t, i18n } = useTranslation();

    const getData = async (table, id) => {
        await socketDB.emit("db", { process: "getByID", table: table, id: id }, async (result) => {
            setSpiral(result.helezon)
            setDistance(result.ektur[0].mesafe)
            setRefHiz(result.refHiz)
            setMotionControl(result.motionCtrl[0])
            setCurrentControl(result.currentCtrl[0])
            setLimitSwitch(result.limitCb)
        })
    }
    const navigate = useNavigate();

    const getSettings = () => {
        if (!isSettingGot) {
            isSettingGot = true
            setData("messages", deviceID, "message", [{ "getsettings": true }])
        }
    }

    useEffect(() => {
        return () => {
            isSettingGot = false
        }
    }, [])

    useEffect(() => {
        const user = getUser()
        if (user != null) {
            socketDB.emit("db", { process: "login", data: user }, async (result) => {
                if (!result) {
                    navigate("/")
                } else {
                    setIsLoginEnded(true)
                }
            })
        } else {
            navigate("/")
            setIsLoginEnded(true)
        }
    }, [])

    useEffect(() => {
        if (messages != null) {
            if (deviceID != -1) {
                getSettings()
                const myIndex = findMyID(messages, deviceID)
                if (Object.keys(messages[myIndex].message[0]).includes("getsettings") && !messages[myIndex].message[0].getSettings) {
                    //getDataManuel("ibold", 1)
                    getData("ibold", deviceID)
                }
            }
        }
    }, [messages, deviceID])

    // useEffect(() => {
    //     Object.keys(timings).length >= 10 && setSaveSettings(false)
    //     Object.keys(timings).length < 10 && setSaveSettings(true)
    // }, [timings])


    const setData = async (table, id, column, data) => {
        if (table === "messages") {
            const myIndex = findMyID(messages, id)
            if (Object.values(messages[myIndex].message[0])[0]) {
                toast.error(t("unfinished"))
            }
            else {
                console.log(data)
                await socketDB.emit("db", { process: "upgrade", table: table, id: id, column: column, data: data })
            }
        } else {
            await socketDB.emit("db", { process: "upgrade", table: table, id: id, column: column, data: data })
        }
    }


    const handleSpiral = (e) => {
        e.preventDefault()
        setSpiral(prev => {
            setData("ibold", deviceID, "helezon", !prev)
            setData("messages", deviceID, "message", [{ "helezon": true }])
            return !prev
        })
        toast.success(t("success"))
    }

    const handleDistanceChange = (e) => {
        setDistance(e.target.value)
    }
    const handleRefHizChange = (e) => {
        setRefHiz(e.target.value)
    }

    const handleRefHizSubmit = (e) => {
        e.preventDefault()
        if (refHiz < 10) {
            toast.error(t("refLower"))
            return;
        } else if (refHiz > 24) {
            toast.error(t("refBigger"))
            return;
        } else {
            let roundedNumber = Math.round(refHiz * 10) / 10;
            setData("ibold", deviceID, "refHiz", roundedNumber)
            setData("messages", deviceID, "message", [{ "refHiz": true }])
            toast.success(t("success"))
        }
    }

    const handleTourSubmit = (e) => {
        e.preventDefault()
        if (distance < 60) {
            toast.error(t("distanceLower"))
            return;
        } else if (distance > 150) {
            toast.error(t("distanceBigger"))
            return;
        } else if (distance % 5 != 0) {
            toast.error(t("distanceMultiple"))
            return;
        } else {
            setData("ibold", deviceID, "ektur", [{ "mesafe": distance, "ektur": true }])
            setData("messages", deviceID, "message", [{ "ektur": true }])
            toast.success(t("success"))
        }
    }

    const handleMotionControlChange = (key, value) => {
        const newValue = { ...motionControl };
        newValue[key] = value;
        setMotionControl(newValue);
    }

    const handleMotionControlSubmit = (e) => {
        e.preventDefault()
        if (Object.keys(motionControl).length < 3 || Object.values(motionControl).includes("")) {
            toast.error(t("missingInfo"))
        } else if (motionControl.rampaTime > 50 || motionControl.rampaTime < 0) {
            toast.error(t("timeValues"))
        } else if (motionControl.delay > 30 || motionControl.delay < 0) {
            toast.error(t("delayValues"))
        } else if (motionControl.rampaStart > 50 || motionControl.rampaStart < 0) {
            toast.error(t("startValues"))
        } else if (motionControl.rampaStart <= motionControl.rampaTime) {
            setData("ibold", deviceID, "motionCtrl", [{ "rampaTime": motionControl.rampaTime, "delay": motionControl.delay, "rampaStart": motionControl.rampaStart }])
            setData("messages", deviceID, "message", [{ "motionCtrl": true }])
            toast.success(t("success"))
        } else {
            toast.error(t("lowerThan"))
            //console.log(motionControl)
        }
    }

    const handleCurrentControlChange = (key, value) => {
        const newValue = { ...currentControl };
        newValue[key] = parseInt(value);
        for (let i = 0; i < Object.keys(newValue).length; i++) {
            newValue[Object.keys(newValue)[i]] = parseInt(newValue[Object.keys(newValue)[i]])
        }
        setCurrentControl(newValue);
    }

    const handleCurrentControlSubmit = (e) => {
        e.preventDefault()
        if (Object.keys(currentControl).length < 2 || Object.values(currentControl).includes("")) {
            toast.error(t("missingInfo"))
        } else if (currentControl.first > 400 || currentControl.first < 0) {
            toast.error(t("firstValue"))
        } else if (currentControl.second > 400 || currentControl.second < 0) {
            toast.error(t("secondValues"))
        } else if (currentControl.first <= currentControl.second) {
            setData("ibold", deviceID, "currentCtrl", [{ "first": currentControl.first, "second": currentControl.second }])
            setData("messages", deviceID, "message", [{ "currentCtrl": true }])
            toast.success(t("success"))
        } else {
            toast.error(t("currentComp"))
            //console.log(motionControl)
        }
    }

    const handleLimitSwitchChange = (e) => {
        setLimitSwitch(e.target.checked)
    }

    const handleLimitSwitchSubmit = (e) => {
        e.preventDefault()
        setData("ibold", deviceID, "limitCb", limitSwitch)
        setData("messages", deviceID, "message", [{ "limitCb": true }])
        toast.success(t("success"))
    }

    const handlePositionSubmit = (e, val) => {
        e.preventDefault()
        setData("ibold", deviceID, "position", val)
        setData("messages", deviceID, "message", [{ "position": true }])
        toast.success(t("success"))
    }

    return (
        <>
            {isLoginEnded ? (<>
                < div className="tab-content" >
                    <Toaster />
                    <div ngbnavpane="" className="tab-pane fade active show" ng-reflect-item="[object Object]"
                        ng-reflect-nav="[object Object]" id="ngb-nav-1-panel" role="tabpanel" aria-labelledby="ngb-nav-1">
                        <div _ngcontent-ng-c334237783="" className="container">
                            <hr _ngcontent-ng-c334237783="" />
                            <form _ngcontent-ng-c334237783="" noValidate="" ng-reflect-form="[object Object]"
                                className="ng-untouched ng-pristine ng-invalid">
                                <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around">
                                    <div _ngcontent-ng-c334237783="" className="text-center mb-2 mt-3"><label
                                        _ngcontent-ng-c334237783="" className="boldcenter" style={{ fontSize: "large" }}> {t("spiralControl").toLocaleUpperCase()}
                                    </label>
                                        <div _ngcontent-ng-c334237783="" className="container">
                                            <button _ngcontent-ng-c334237783="" id="helezon" className="btn m-2 mt-3 text-center"
                                                style={{ backgroundColor: "#ececee" }}
                                                onClick={(e) => handleSpiral(e)}> {spiral ? t("stopSpiral") :t("runSpiral")} </button></div>
                                    </div>
                                </div>
                            </form>
                            <form _ngcontent-ng-c334237783="" noValidate="" ng-reflect-form="[object Object]"
                                className="ng-untouched ng-pristine ng-invalid">
                                <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around">
                                    <div _ngcontent-ng-c334237783="" className="col"></div>
                                </div>
                                <hr _ngcontent-ng-c334237783="" />
                                <div _ngcontent-ng-c334237783="" className="text-center mb-2 mt-3"><label _ngcontent-ng-c334237783=""
                                    className="boldcenter" style={{ fontSize: "large" }}> {t("additionalTourControl").toLocaleUpperCase()} </label></div>
                                <div _ngcontent-ng-c334237783="" className="row mt-3">
                                    <div _ngcontent-ng-c334237783="" className="col-md-2"></div>
                                    <div _ngcontent-ng-c334237783="" className="col-md-4"><label _ngcontent-ng-c334237783=""
                                        htmlFor="tourdist" className="boldcenter" style={{ color: "#f1d00a" }}> {t("distance")}: </label><input
                                            _ngcontent-ng-c334237783="" formcontrolname="tourdist" type="number" name="tourdist"
                                            id="tourdist" min="60" max="150" step="5" value={distance} onChange={(e) => handleDistanceChange(e)}
                                            className="form-control ng-untouched ng-pristine ng-invalid" ng-reflect-min="60"
                                            ng-reflect-max="150" ng-reflect-name="tourdist" placeholder={t("distance")} /></div>
                                    <div _ngcontent-ng-c334237783="" className="col-md-4 text-center">
                                        <button _ngcontent-ng-c334237783="" id="ektur" className="btn turIleri m-3" onClick={(e) => handleTourSubmit(e)} ><span
                                            _ngcontent-ng-c334237783=""> {t("additionalTour").toLocaleUpperCase()}</span></button></div>
                                    <div _ngcontent-ng-c334237783="" className="col-md-2"></div>
                                    <hr _ngcontent-ng-c334237783="" className="text-muted mt-3 mb-3" />
                                </div>
                            </form>
                            <form _ngcontent-ng-c334237783="" noValidate="" ng-reflect-form="[object Object]"
                                className="ng-untouched ng-pristine ng-invalid">
                                <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around">
                                    <div _ngcontent-ng-c334237783="" className="col"></div>
                                </div>
                                <hr _ngcontent-ng-c334237783="" />
                                <div _ngcontent-ng-c334237783="" className="text-center mb-2 mt-3"><label _ngcontent-ng-c334237783=""
                                    className="boldcenter" style={{ fontSize: "large" }}> {t("referanceSpeedControl").toLocaleUpperCase()} </label></div>
                                <div _ngcontent-ng-c334237783="" className="row mt-3">
                                    <div _ngcontent-ng-c334237783="" className="col-md-2"></div>
                                    <div _ngcontent-ng-c334237783="" className="col-md-4"><label _ngcontent-ng-c334237783=""
                                        htmlFor="tourdist" className="boldcenter" style={{ color: "#f1d00a" }}> {t("referanceSpeed")}: </label><input
                                            _ngcontent-ng-c334237783="" formcontrolname="tourdist" type="number" name="tourdist"
                                            id="tourdist" min="10" max="24" step=".1" value={refHiz} onChange={(e) => handleRefHizChange(e)}
                                            className="form-control ng-untouched ng-pristine ng-invalid" ng-reflect-min="60"
                                            ng-reflect-max="150" ng-reflect-name="tourdist" placeholder={t("referanceSpeed")} /></div>
                                    <div _ngcontent-ng-c334237783="" className="col-md-4 text-center"><button
                                        _ngcontent-ng-c334237783="" id="gecikme" className="btn m-1 mt-3 text-center"
                                        style={{ backgroundColor: "#ececee" }} disabled="" onClick={(e) => handleRefHizSubmit(e)}><span _ngcontent-ng-c334237783=""> {t("save")}</span></button></div>
                                    <hr _ngcontent-ng-c334237783="" className="text-muted mt-3 mb-3" />
                                </div>
                            </form>
                            <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around">
                                <form _ngcontent-ng-c334237783="" noValidate="" ng-reflect-form="[object Object]"
                                    className="ng-untouched ng-pristine ng-invalid">
                                    <div _ngcontent-ng-c334237783="" className="text-center mb-3"><label _ngcontent-ng-c334237783=""
                                        className="boldcenter" style={{ fontSize: "large" }}> {t("motionControl").toLocaleUpperCase()} </label></div>
                                    <div _ngcontent-ng-c334237783="" className="row">
                                        <div _ngcontent-ng-c334237783="" className="col-md-4">
                                            <div _ngcontent-ng-c334237783="" className="form-group"><label _ngcontent-ng-c334237783=""
                                                htmlFor="rampa_giris" className="text-center boldcenter" style={{ color: "#ff7315" }}> {t("rampTime")}:
                                            </label><input _ngcontent-ng-c334237783="" formcontrolname="ramp_time" type="number"
                                                name="ramp_time" id="ramp_time" min="0" max="50" value={motionControl.rampaTime} onChange={(e) => handleMotionControlChange("rampaTime", e.target.value)}
                                                className="form-control ng-untouched ng-pristine ng-invalid" ng-reflect-min="0"
                                                ng-reflect-max="50" ng-reflect-name="ramp_time" placeholder={t("rampTime")} /></div>
                                        </div>
                                        <div _ngcontent-ng-c334237783="" className="col-md-4"><label _ngcontent-ng-c334237783=""
                                            htmlFor="delay" className="boldcenter" style={{ color: "#f1d00a" }}> {t("delay")}: </label><input
                                                _ngcontent-ng-c334237783="" formcontrolname="delay" type="number" name="delay" id="delay"
                                                min="0" max="30" className="form-control ng-untouched ng-pristine ng-invalid" value={motionControl.delay} onChange={(e) => handleMotionControlChange("delay", e.target.value)}
                                                ng-reflect-min="0" ng-reflect-max="30" ng-reflect-name="delay" placeholder={t("delay")} /></div>
                                        <div _ngcontent-ng-c334237783="" className="col-sm"><label _ngcontent-ng-c334237783=""
                                            htmlFor="ramp_up" className="boldcenter" style={{ color: "#96cd39" }}>{t("rampStart")}:</label><input
                                                _ngcontent-ng-c334237783="" formcontrolname="ramp_up" type="number" name="ramp_up" value={motionControl.rampaStart} onChange={(e) => handleMotionControlChange("rampaStart", e.target.value)}
                                                id="ramp_up" min="0" max="50" className="form-control ng-untouched ng-pristine ng-invalid"
                                                ng-reflect-min="0" ng-reflect-max="50" ng-reflect-name="ramp_up" placeholder={t("rampStart")} />
                                        </div>
                                    </div>
                                    <div _ngcontent-ng-c334237783="" className="col-md-12 text-center"><button
                                        _ngcontent-ng-c334237783="" id="gecikme" className="btn m-1 mt-3 text-center"
                                        style={{ backgroundColor: "#ececee" }} disabled="" onClick={(e) => { handleMotionControlSubmit(e) }}><span _ngcontent-ng-c334237783=""> {t("saveMotionSettings")}</span></button></div>
                                </form>
                            </div>

                            <hr _ngcontent-ng-c334237783="" className="text-muted mt-2 mb-2" />
                            <div _ngcontent-ng-c334237783="" className="container">
                                <form _ngcontent-ng-c334237783="" noValidate="" ng-reflect-form="[object Object]"
                                    className="ng-untouched ng-pristine ng-invalid">
                                    <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around"><label
                                        _ngcontent-ng-c334237783="" className="boldcenter" style={{ fontSize: "large" }}> {t("currentControl").toLocaleUpperCase()}
                                    </label>
                                        <div _ngcontent-ng-c334237783="" className="row justify-content-center">
                                            <div _ngcontent-ng-c334237783="" className="col-md-4">
                                                <div _ngcontent-ng-c334237783="" className="form-group"><label _ngcontent-ng-c334237783=""
                                                    htmlFor="cr_first" className="boldcenter"> {t("firstLevel")}:</label><input
                                                        _ngcontent-ng-c334237783="" type="number" name="" id="cr_first" min="0" max="400"
                                                        defaultValue={0} step="5" formcontrolname="cr_first"
                                                        className="form-control ng-untouched ng-pristine ng-invalid" ng-reflect-min="0"
                                                        ng-reflect-max="400" ng-reflect-name="cr_first" value={currentControl.first} placeholder={t("firstLevel")} onChange={(e) => handleCurrentControlChange("first", e.target.value)} /></div>
                                            </div>
                                            <div _ngcontent-ng-c334237783="" className="col-md-4">
                                                <div _ngcontent-ng-c334237783="" className="form-group"><label _ngcontent-ng-c334237783=""
                                                    htmlFor="cr_second" className="boldcenter"> {t("secondLevel")}:</label><input
                                                        _ngcontent-ng-c334237783="" type="number" name="" id="cr_second" min="0" max="600"
                                                        defaultValue={0} step="5" formcontrolname="cr_second" value={currentControl.second}
                                                        className="form-control ng-untouched ng-pristine ng-invalid" ng-reflect-min="0"
                                                        ng-reflect-max="600" ng-reflect-name="cr_second" placeholder={t("secondLevel")} onChange={(e) => handleCurrentControlChange("second", e.target.value)} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div _ngcontent-ng-c334237783="" className="row">
                                        <div _ngcontent-ng-c334237783="" className="text-center">
                                            <div _ngcontent-ng-c334237783="" className="col-md-12 text-center"><button
                                                _ngcontent-ng-c334237783="" id="savecurrents" className="btn m-1 mt-3"
                                                style={{ backgroundColor: "#ececee" }} disabled="" onClick={(e) => { handleCurrentControlSubmit(e) }}><span _ngcontent-ng-c334237783="">{t("saveCurrent")}</span></button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <hr _ngcontent-ng-c334237783="" />
                            <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around">
                                <form _ngcontent-ng-c334237783="" noValidate="" ng-reflect-form="[object Object]"
                                    className="ng-untouched ng-pristine ng-invalid">
                                    <div _ngcontent-ng-c334237783="" className="text-center mb-2 mt-3"><label
                                        _ngcontent-ng-c334237783="" className="boldcenter" style={{ fontSize: "large" }}> {t("limitSwitchControl")} </label></div>
                                    <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around">
                                        <div _ngcontent-ng-c334237783="" className="form-group">
                                            <div _ngcontent-ng-c334237783="" className="text-center mb-2 mt-3"><label
                                                _ngcontent-ng-c334237783="" className="boldcenter"> {t("disableLimitSwitch")}: </label><input
                                                    _ngcontent-ng-c334237783="" formcontrolname="limitcb" type="checkbox"
                                                    defaultChecked={limitSwitch} checked={limitSwitch} name="limitcb" onChange={(e) => handleLimitSwitchChange(e)}
                                                    id="limitcb" className="m-2 ng-untouched ng-pristine ng-invalid" ng-reflect-name="limitcb" />
                                            </div>
                                            <div _ngcontent-ng-c334237783="" className="col-md-12 text-center"><button
                                                _ngcontent-ng-c334237783="" id="limitcb" className="btn m-2 mt-3 text-center"
                                                style={{ backgroundColor: "#ececee" }} onClick={(e) => handleLimitSwitchSubmit(e)}><span
                                                    _ngcontent-ng-c334237783="">{t("save")}</span></button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <hr _ngcontent-ng-c334237783="" className="text-muted mt-2 mb-2" />
                        </div>
                        <div _ngcontent-ng-c334237783="" className="row d-flex justify-content-around">
                            <div _ngcontent-ng-c334237783="" className="col">
                                <form _ngcontent-ng-c334237783="" noValidate="" ng-reflect-form="[object Object]"
                                    className="ng-untouched ng-pristine ng-invalid">
                                    <div _ngcontent-ng-c334237783="" className="col d-flex justify-content-around">
                                        <div _ngcontent-ng-c334237783="" className="text-center mb-2 mt-3"><label
                                            _ngcontent-ng-c334237783="" className="boldcenter" style={{ fontSize: "large" }}> {t("positionControl")}
                                        </label>
                                            <div _ngcontent-ng-c334237783="" className="container">
                                                <button _ngcontent-ng-c334237783="" id="helezon" className="btn m-2 mt-3 text-center" style={{ backgroundColor: "#ececee" }} onClick={(e) => handlePositionSubmit(e, "first")}> {t("firstPos")} </button>
                                                <button _ngcontent-ng-c334237783="" id="helezon" className="btn m-2 mt-3 text-center" style={{ backgroundColor: "#ececee" }} onClick={(e) => handlePositionSubmit(e, "second")}> {t("secondPos")}</button>
                                                <button _ngcontent-ng-c334237783="" id="helezon" className="btn m-2 mt-3 text-center" style={{ backgroundColor: "#ececee" }} onClick={(e) => handlePositionSubmit(e, "third")}> {t("thirdPos")} </button>
                                                <button _ngcontent-ng-c334237783="" id="helezon" className="btn m-2 mt-3 text-center" style={{ backgroundColor: "#ececee" }} onClick={(e) => handlePositionSubmit(e, "fourth")}> {t("fourthPos")} </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </>) : (<></>)}
        </>
    )
}

export default Settings