import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from 'socket.io-client';
import _ from "lodash"
import { getUser } from "../utils/utils";

const StateContext = createContext();
const URL = "http://94.154.34.33:4010"
const socket = io(URL, { autoConnect: true });

export const ContextProvider = ({ children }) => {
    const [messages, setMessages] = useState(null)
    const [deviceID, setDeviceID] = useState(-1)
    // "undefined" means the URL will be computed from the `window.location` object
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    const socketDB = socket
    let msgCheck = []
    useEffect(() => {

        socket.on('messages', (message) => {
            if (!_.isEqual(msgCheck, message)) {
                setMessages(message)
                msgCheck = message
            }
        });

        // return () => {
        //     socket.disconnect();
        // };
    }, []);

    useEffect(() => {
        const user = getUser()
        if (user !== null) {
            setDeviceID(user.deviceID)
        }
    }, []);

    const findMyID = (messages, id) => {
        let myIndex = -1;
        for (let i = 0; i < messages.length; i++) {
            const item = messages[i].id;
            if (item == id) {
                myIndex = i;
                break;
            }
        }
        return myIndex
    }
    // const strToObj = (str) => {
    //     const rep = str.replaceAll('""', '"')
    //     const obj = JSON.parse(rep.slice(2, rep.length - 2))
    //     return obj
    // }

    //const rs = getData("getByID", "ibold", 1)
    //console.log(rs)
    return (
        <StateContext.Provider
            value={{
                socketDB,
                sleep,
                messages,
                deviceID,
                setDeviceID,
                findMyID
                //strToObj
            }}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);
