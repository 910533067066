import React, { useEffect, useRef, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { BiSolidLock } from "react-icons/bi";
// import loginBg from "../../assets/loginBg.jpg";
// import iconWhite from "../../assets/iconWhite.svg";
import { useFormik } from "formik";
import { HiMiniEye, HiMiniEyeSlash } from "react-icons/hi2";
import { loginSchema } from "../utils/FormSchemas";
import { useStateContext } from "../context/ContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import itechIcon from '../assets/icon.png'
import toast, { Toaster } from "react-hot-toast";
import { encryptData, getUser } from "../utils/utils";
import { useTranslation } from "react-i18next";
// import {
//     LoggedIn,
// } from "../utils/Utils";

const Login = () => {
    const [isLoginEnded, setIsLoginEnded] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loginError, setLoginError] = useState("");
    const navigate = useNavigate();
    const { socketDB, setDeviceID,findMyID  } = useStateContext()
    const validationSchema = loginSchema;
    const { t, i18n } = useTranslation();

    const formik = useFormik({
        initialValues: {
            deviceID: "",
            password: "",
            rememberMe: false,
        },
        onSubmit: (values) => {
            login(values);
        },
        validationSchema
    });

    useEffect(() => {
        const user = getUser()
        if (user != null) {
            socketDB.emit("db", { process: "login", data: user }, async (result) => {
                if (result) {
                    setDeviceID(user.deviceID)
                    navigate("/home")
                } else {
                    setIsLoginEnded(true)
                }
            })
        } else {
            setIsLoginEnded(true)
        }
    }, [])

    const showPasswordTrue = () => {
        setShowPassword("password");
    };

    const showPasswordFalse = () => {
        setShowPassword("text");
    };

    const login = async () => {
        await socketDB.emit("db", { process: "login", data: formik.values }, async (result) => {
            if (result) {
                const saveData = formik.values
                setDeviceID(formik.values.deviceID)
                if (formik.values.rememberMe) {
                    delete saveData["rememberMe"]
                    localStorage.setItem("user", encryptData(saveData))
                } else {
                    delete saveData["rememberMe"]
                    sessionStorage.setItem("user", encryptData(saveData))
                }
                navigate("/home")
            } else {
                toast.error(t("notExist"))
            }
        })

    }
    const handleCheck = (val) => {
        formik.values.rememberMe = val
    }

    return (
        <div>
            <Toaster />
            {isLoginEnded ? (<>
                <div className="flex flex-col items-center justify-center w-screen h-screen lg:px-0  ">
                    <div className="flex items-center justify-center z-10 -my-16 w-[150px] h-[150px] bg-[#5396da] p-3 rounded-full border-4 border-white">
                        <img src={itechIcon} className="z-10 w-full h-full rounded-full " alt="" />
                    </div>
                    <div className="flex items-center justify-center border-[3px]  border-[#5396da] rounded-[22px] shadow-xl ">
                        <div className="flex items-center justify-center bg-white p-8 rounded-[20px] ">
                            <div className="flex flex-col h-fit justify-center items-center ">
                                <form
                                    className="mt-10 flex flex-col gap-5 w-full my-50 pb-5 pt-5"
                                    onSubmit={formik.handleSubmit}
                                >
                                    <label className="flex items-center bg-gray-100 py-4 px-4 rounded-lg">
                                        <div className="">
                                            <FaUserAlt />
                                        </div>
                                        <input
                                            type="text"
                                            name="deviceID"
                                            value={formik.values.deviceID}
                                            onChange={formik.handleChange}
                                            onFocus={() => setLoginError("")}
                                            placeholder={t("typeDeviceID")}
                                            className="ml-2 w-full bg-gray-100 text-secondary text-black  outline-none border-none text-[16px] placeholder:text-[16px]"
                                        />
                                    </label>
                                    <label className="flex items-center bg-gray-100 py-4 px-4 rounded-lg">
                                        <div className="h-[23px] w-[23px]">
                                            <BiSolidLock className="h-full w-full" />
                                        </div>
                                        <input
                                            type={showPassword}
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onFocus={() => setLoginError("")}
                                            placeholder={t("typePassword")}
                                            className=" ml-2 w-full bg-gray-100 text-secondary text-black  outline-none border-none text-[16px] placeholder:text-[16px]"
                                        />
                                        <div className="ml-2 h-[23px] w-[23px] cursor-pointer">
                                            {showPassword === "password" ? (
                                                <HiMiniEye
                                                    className="h-full w-full"
                                                    onClick={showPasswordFalse}
                                                />
                                            ) : (
                                                <HiMiniEyeSlash
                                                    className="h-full w-full"
                                                    onClick={showPasswordTrue}
                                                />
                                            )}
                                        </div>
                                    </label>
                                    <div className="flex flex-row gap-4 items-center justify-between">
                                        <div className="flex flex-row items-center w-full">
                                            <div className="flex items-center gap-2 ">
                                                <input _ngcontent-ng-c334237783="" name="rememberMe" value={formik.values.rememberMe} onFocus={() => setLoginError("")}
                                                    type="checkbox" onChange={(e) => handleCheck(e.target.checked)} className="m-2 ng-untouched ng-pristine ng-invalid"
                                                />
                                            </div>
                                            <p className="text-gray-500 text-[15px] ml-2 mt-3 font-semibold align-left">
                                                {t("rememberMe")}
                                            </p>
                                        </div>
                                    </div>
                                    {formik.errors.deviceID == null &&
                                        formik.errors.password == null &&
                                        loginError != "" && (
                                            <div className="text-red-600">{loginError}</div>
                                        )}
                                    {formik.errors.deviceID != null &&
                                        formik.errors.password != null &&
                                        isSubmitted ? (
                                        <div className="text-red-600">{formik.errors.deviceID}</div>
                                    ) : (
                                        <>
                                            {formik.errors.deviceID != null && isSubmitted && (
                                                <div className="text-red-600">
                                                    {formik.errors.deviceID}
                                                </div>
                                            )}
                                            {formik.errors.password != null && isSubmitted && (
                                                <div className="text-red-600">
                                                    {formik.errors.password}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <button
                                        type="submit"
                                        onClick={() => {
                                            setIsSubmitted(true)
                                            if (Object.keys(formik.errors).length == 0) {
                                                login(formik.values)
                                            }
                                        }}
                                        className="w-full text-white font-bold rounded-2xl bg-[#5396da] shadow-xl  py-3 p-5 mt-5 cursor-pointer"
                                    >
                                        {t("login")}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>) : (<></>)}
        </div>
    )
}

export default Login